html {
  font-size: 62.5%;

  @include screen(small) {
    font-size: 58%;
  }
}


body {
  @include sexyfont;
  font-family: var(--font-primary);
  color: rgba(var(--theme-black-rgb), 1);
  height: 100%;
  position: relative;
  font-size: 1.6rem;
  background: #f3f4f5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-heading);
  color: rgba(var(--theme-black-rgb), 1);
}

a {
  color: rgba(var(--theme-black-rgb), 1);
  font-family: var(--font-primary);
  text-decoration: none;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: rgba(var(--white-rgb), .6);
  }

  &:focus {
    outline: none;
  }

  &.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 52px;
    padding: 14px 35px;
    border-radius: 26px;
    border: 0;
    font-family: var(--font-heading);
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 150ms linear,
      color 150ms linear;

  }
}