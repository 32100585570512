.yc-global-header {
  padding: 1.7rem 0 1rem;
  z-index: 100;
  position: relative;

  .container {
    @media only screen and (min-width: 1400px) {
      max-width: 1280px;
    }
  }

  &__navigation {
    &__nav {
      @media screen and (max-width: 760px) {
        justify-content: center;
        margin: 2rem 0;
      }
    }

    &__link {
      list-style-type: none;
      margin-right: 2.8rem;

      @media screen and (max-width: 760px) {
        margin-bottom: 1.6rem;
      }

      a {
        font-family: var(--font-heading);
        font-size: 1.6rem;
        font-weight: 500;
        color: rgba(var(--white-rgb), .8);
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      padding: 14px 35px;
      border-radius: 26px;
      border: 0;
      background: var(--theme-primary);
      // background-image: linear-gradient(180deg, #41cefe, #00a4ff);
      font-family: var(--font-heading);
      font-size: 1.4rem;
      font-weight: 600;
      color: var(--white);
      cursor: pointer;
      transition: background-color 150ms linear,
        color 150ms linear;
      margin-left: 2rem;
    }

    &__socials {
      ul.nav {
        li.nav-item {
          margin-left: 0.8rem;

          a.nav-link {
            background: #051730;
            display: flex;
            height: 36px;
            width: 36px;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            padding: 0;

            i {
              color: #d4cfe3;
            }
          }
        }
      }
    }
  }
}