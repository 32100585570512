@import "../../sass/main.scss";

.yc-landing {
  position: relative;

  &__banner {
    position: relative;
    // min-height: 60vh;
    min-height: 100vh;
    background: url(./images/grid-bg.svg), #030306;
    overflow: hidden;
    padding-bottom: 2rem;
    // background: #06151c;
    // background: url(./images/grid-bg.svg), linear-gradient(0deg, #000000 0%, #070336 100%);
    // background: url(./images/grid-bg.svg), linear-gradient(0deg, #010C1A 0%, #070336 100%);

    &__manifest {
      padding: 10rem 0 14.6rem;
      z-index: 5000;
      position: relative;

      @media screen and (max-width: 760px) {
        padding-top: 6rem;
      }

      @media only screen and (min-width: 1400px) {
        padding: 15rem 0 14.6rem;
      }

      .container {
        @media only screen and (min-width: 1400px) {
          max-width: 1300px;
        }
      }

      &__text {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-left: -72px;

        @media screen and (max-width: 760px) {
          margin-left: 1rem;
        }

        // &::after {
        //   content: url(./images/swup-alt.svg);
        //   display: block;
        //   position: absolute;
        //   top: 62px;
        //   left: -2px;
        // }

        &::before {
          content: url(./images/star.svg);
          display: block;
          position: absolute;
          top: -7px;
          left: 210px;

          @media screen and (max-width: 760px) {
            left: 0px;
          }

          @media only screen and (min-width: 1400px) {
            left: 305px;
          }

        }

        h2 {
          font-family: "recoleta";
          font-weight: 500;
          font-size: 60px;
          color: rgba(var(--white-rgb), 1);
          line-height: 74px;
          // text-transform: capitalize;
          position: relative;

          @media screen and (max-width: 760px) {
            font-size: 44px;
            line-height: 56px;
          }

          span {
            font-family: var(--font-heading);
            letter-spacing: -0.09rem;
          }

          &::before {
            content: url(./images/avatar-pair.svg);
            display: block;
            position: absolute;
            right: -172px;
            top: -36px;

            @media screen and (max-width: 760px) {
              display: none;
            }

            @media only screen and (min-width: 1400px) {
              right: -172px;
              top: -36px;
            }
          }

          &::after {
            content: url(./images/trend.svg);
            display: block;
            position: absolute;
            bottom: -3px;
            right: 48px;

            @media screen and (max-width: 760px) {
              right: 25px;
            }

            @media only screen and (min-width: 1400px) {
              bottom: -3px;
              right: 48px;
            }

          }
        }

        p {
          font-family: var(--font-heading);
          font-size: 1.8rem;
          font-weight: 500;
          color: rgba(var(--white-rgb), 0.8);
          line-height: 36px;
          max-width: 587px;
          margin: 2rem 0 3.6rem;

          @media screen and (max-width: 760px) {
            margin-bottom: 1.2rem;
          }
        }
      }

      &__illustration {
        position: relative;
        height: 100%;
        width: 100%;
        z-index: 8000;

        &::before {
          content: url(./images/globe-alt.svg);
          display: block;
          position: absolute;
          // right: -96px;
          // top: -88px;
          right: -200px;
          top: -55px;
          z-index: 400;

          @media only screen and (min-width: 1400px) {
            right: -100px;
          }
        }

        .globe-side {
          width: 50%;
          z-index: 5000;
          position: absolute;

          &-one {
            right: -100px;
            top: 75px;
            animation-delay: 1.25s;

            @media only screen and (min-width: 1400px) {
              right: -100px;
            }
          }

          &-two {
            left: -82px;
            top: 135px;
            animation-delay: 1.5s;
          }

          &-three {
            right: 102px;
            top: -70px;
            animation-delay: 1.8s;
          }

          &-four {
            right: 124px;
            bottom: -51px;
            animation-delay: 2s;
          }
        }
      }

      // content: "";
      // display: block;
      // position: absolute;
      // z-index: 400;
      // top: -80px;
      // right: -111px;
      // width: 600px;
      // height: 769px;
      // background-size: 601px 759px;
      // background-repeat: no-repeat;
      // background-image: url(http://localhost:3000/static/media/illus-alt-main.f498a57….svg);

      &__buttons {
        a {
          background: var(--theme-primary);
          // background-image: linear-gradient(180deg, #41cefe, #00a4ff);
          color: var(--white);
          // clip-path: polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%);
          // border-radius: 0;

          &.landing-secondary {
            background: #ffffff;
            border: 1px solid #ffffff;
            color: #040712;

            pointer-events: none;
            filter: opacity(0.5);
            cursor: crosshair;
          }
        }

        .btn-social {
          background: #0b2454;

          i {
            margin-right: 1rem;
          }
        }
      }
    }
  }

  &__features {
    min-height: 80rem;
    padding: 7rem 0 4rem;
    position: relative;
    // overflow: hidden;

    &__header {
      position: relative;

      &::after {
        content: url(./images/plus.svg);
        display: block;
        position: absolute;
        top: 138px;
        right: 80px;

        @media screen and (max-width: 760px) {
          display: none;
        }

        @media only screen and (min-width: 1400px) {
          top: 60px;
          right: 190px;
        }
      }

      // &::before {
      //   content: url(./images/plus.svg);
      //   display: block;
      //   position: absolute;
      //   top: -50px;
      //   left: -39px;
      //   transform: rotate(-92deg);
      // }

      &__text {
        h6 {
          font-size: 60px;
          max-width: 1068px;
          margin-top: 2.5rem;

          span {
            background: #02a4fd;
            display: inline-block;
            padding: 1rem 2rem;
            color: #fff;

            span {
              padding: 0;
              color: #f9dd53;
              font-family: "recoleta";
              font-weight: 500;
            }
          }
        }

        h4 {
          font-size: 56px;
          max-width: 900px;
          margin-top: 3.2rem;
          line-height: 64px;
        }

        p {
          font-size: 23px;
          max-width: 720px;
          margin: 3rem 0;
          line-height: 36px;
        }
      }
    }

    &__grid {
      position: relative;
      margin: 10rem 0;

      .container {
        @media only screen and (min-width: 1400px) {
          max-width: 1230px;
        }
      }

      &__items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-auto-columns: 1fr;
        gap: 16px 16px;
        grid-auto-flow: row;
        grid-template-areas:
          "yc-landing__features__grid__items__block__first yc-landing__features__grid__items__block__first yc-landing__features__grid__items__block__second yc-landing__features__grid__items__block__second"
          "yc-landing__features__grid__items__block__first yc-landing__features__grid__items__block__first yc-landing__features__grid__items__block__third yc-landing__features__grid__items__block__third"
          "yc-landing__features__grid__items__block__fourth yc-landing__features__grid__items__block__fourth yc-landing__features__grid__items__block__fourth yc-landing__features__grid__items__block__fourth"
          "yc-landing__features__grid__items__block__fourth yc-landing__features__grid__items__block__fourth yc-landing__features__grid__items__block__fourth yc-landing__features__grid__items__block__fourth";
        gap: 24px;
        width: 100%;
        height: 100%;

        @media screen and (max-width: 760px) {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr 1fr;
          grid-template-areas: "yc-landing__features__grid__items__block__first yc-landing__features__grid__items__block__first"
            "yc-landing__features__grid__items__block__second yc-landing__features__grid__items__block__second"
            "yc-landing__features__grid__items__block__third yc-landing__features__grid__items__block__third";
        }

        &__block {
          min-height: 440px;
          display: flex;
          justify-content: center;
          text-align: center;
          border-radius: 32px;
          background-color: #ffffffba;
          background-position: center center;
          /* border: 1px solid #05151b; */
          /* box-shadow: 3px 3px 0 #002f3c !important; */
          padding: 2rem 5rem;
          position: relative;
          overflow: hidden;

          &__header {
            margin-top: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;

            &>div {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 20px;

              img {
                position: relative;
                width: 28px;
              }
            }

            h5 {
              font-size: 29px;
              font-weight: 600;
              margin-bottom: 0;
            }

            h6 {
              font-size: 20px;
              line-height: 32px;
              padding-top: 8px;
              margin: 16px auto 28px;
              position: relative;
              z-index: 50000;
              max-width: 420px;
            }

            a {
              background: transparent;
              border: 1px solid rgba(var(--theme-primary-rgb), 0.8);
              width: 150px;
              height: 45px;
              display: flex;
              border-radius: 50px;
              justify-content: center;
              align-items: center;
              color: var(--theme-primary) !important;
            }
          }

          &__image {
            img {
              position: absolute;
              width: 100%;
              margin-top: 3.2rem;

            }

            &.first {
              img {
                left: 2%;
                top: 33%;
              }
            }

            &.second,
            &.third {
              img {
                left: 2%;
                bottom: -20px;
              }
            }

            &.fourth {
              img {
                left: 34%;
                top: 6%;
              }
            }
          }

          &__first {
            grid-area: yc-landing__features__grid__items__block__first;
          }

          &__second {
            grid-area: yc-landing__features__grid__items__block__second;
          }

          &__third {
            grid-area: yc-landing__features__grid__items__block__third;
          }

          &__fourth {
            grid-area: yc-landing__features__grid__items__block__fourth;
            max-height: 460px;
            justify-content: flex-start;
            text-align: left;

            .yc-landing__features__grid__items__block__header {
              &>div {
                justify-content: flex-start;
              }
            }
          }
        }
      }
    }
  }

  &__cta {
    background: #031220;
    position: relative;
    padding: 6rem 0 1rem;
    margin: 10rem 0 0;

    .container {
      @media only screen and (min-width: 1400px) {
        max-width: 1120px;
      }
    }

    &__text {
      p {
        color: rgba(var(--white-rgb), 0.8);
      }

      h2 {
        font-size: 48px;
        line-height: 64px;
        color: rgba(var(--white-rgb), 1);
        margin-bottom: 4rem;
      }
    }

    &__button {
      display: flex;

      a.btn {
        background: var(--theme-primary);
        // background-image: linear-gradient(180deg, #41cefe, #00a4ff);
        color: var(--white);

        &.secondary {
          background: #ffffff;
          border: 1px solid #ffffff;
          color: #040712;
        }
      }
    }

    &__bg {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 20;
      width: 100%;
      margin: 0 auto;

      &-before {
        top: -43px;

        @media screen and (max-width: 760px) {
          top: -13px;
        }

        @media only screen and (min-width: 1400px) {
          top: -54px;
        }
      }

      &-after {
        bottom: -154px;

        @media screen and (max-width: 760px) {
          bottom: -42px;
        }

        @media only screen and (min-width: 1400px) {
          bottom: -188px;
        }
      }
    }

    &__img {
      img {
        width: 100%;

        @media screen and (max-width: 760px) {
          padding: 4rem;
        }
      }
    }
  }

  &__about {
    position: relative;
    padding: 18rem 0 8rem;
    background: #fff;

    .container {
      @media only screen and (min-width: 1400px) {
        max-width: 1280px;
      }
    }

    &__header {
      &__text {
        h6 {
          font-weight: 700;
          width: 122px;
          color: var(--theme-primary);
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 32px;
          font-size: 15px;
          border-radius: 60px;
        }

        h2 {
          font-size: 36px;
          line-height: 48px;
          margin-bottom: 1.6rem;
          color: rgba(var(--theme-black-rgb), 1);
          max-width: 560px;
        }

        p {
          font-size: 20px;
          line-height: 29px;
          margin: 2rem 0 1.6rem;
          color: rgba(var(--theme-black-rgb), 0.8);
          max-width: 620px;
        }
      }
    }

    &__list {
      padding: 4rem 0 3rem;

      &__heading {
        h3 {
          font-size: 24px;
          line-height: 36px;
          font-weight: 500;
          margin-bottom: 4rem;
          color: rgba(var(--theme-black-rgb), 1);
        }
      }

      &__item {
        margin-bottom: 4rem;

        img {
          width: 48px;
          margin-bottom: 2rem;
        }

        h2 {
          font-size: 17px;
          line-height: 36px;
          font-weight: 700;
          margin-bottom: 1.6rem;
          color: rgba(var(--theme-black-rgb), 1);
          max-width: 340px;
        }

        p {
          display: flex;
          align-items: center;
          gap: 0.8rem;
          margin-bottom: 2rem;
        }

      }
    }
  }
}

.banner-hills {
  @media screen and (max-width: 760px) {
    top: -15px;
  }

  position: absolute;
  left: 0;
  right: 0;
  top: -52px;
  z-index: 7000;
  width: 100%;
  margin: 0 auto;

  @media only screen and (min-width: 1400px) {
    top: -52px;
  }
}

// .bird_home-glow {
//   position: absolute;
//   top: -75%;
//   right: auto;
//   bottom: auto;
//   z-index: 0;
//   width: 400px;
//   background-image: radial-gradient(circle farthest-side at 50% 82%, #e1384f91, #f7c8dcc9);
//   -webkit-filter: blur(80px);
//   filter: blur(80px);
//   -webkit-transition: all .3s ease;
//   transition: all .3s ease;
//   left: -10%;
//   height: -webkit-fill-available;
//   opacity: .4;
//   border-radius: 13%;
//   background-color: transparent;
//   z-index: 1000;
// }

.background-glow {
  z-index: 1;
}

.background-glow__wrap {
  width: 100%;
  position: relative;
  height: 100%;
  filter: blur(240px);
}

.background-glow__one {
  position: absolute;
  left: -63px;
  top: -166px;
  width: 500px;
  height: 500px;
  /* margin-top: -250px; */
  /* margin-left: -250px; */
  border-radius: 100%;
  background-color: #094bad57;
}

.background-glow__two {
  position: absolute;
  border-radius: 362.53px;
  transform: rotate(-35.6deg);
  mix-blend-mode: overlay;
  right: 261px;
  background: radial-gradient(65.67% 65.67% at 41.2% 34.33%,
      #094bada1 40%,
      #1ba0f8b9 100%);
  top: 200px;
  width: 500px;
  height: 500px;
}

// .grained {
//   position: initial !important;
// }

// .background-glow__three {
//   position: absolute;
//   left: 290px;
//   top: 218px;
//   width: 500px;
//   height: 500px;
//   border-radius: 100%;
//   z-index: 4;
//   background: radial-gradient(65.67% 65.67% at 41.2% 34.33%, #030818 40%, #030409 100%);
// }