:root {
  --font-primary: "Averta", Arial, sans-serif;
  --font-heading: "PlusJakartaSans", Arial, sans-serif;
  --white: #fff;
  --black: #000;
  --white-rgb: 255, 255, 255;
  --black-rgb: 0, 0, 0;
  --theme-black: #0a2440;
  --theme-black-rgb: 10, 36, 64;
  --theme-primary: #00a4ff;
  --theme-primary-rgb: 0, 164, 255;
  --theme-accent: #ffe254;
  --theme-accent-rgb: 2555, 226, 84;
}