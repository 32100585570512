$breakpoint-small: 768px;
$breakpoint-med: 1028px;
$breakpoint-large: 1280px;
$breakpoint-xlarge: 1440px;

//mixins
@mixin sexyfont {
  text-rendering: optimizeLegibility !important;
  font-smooth: always !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* MIXINS FOR MEDIA QUERIES ----------- */

@mixin screen($size, $type: max, $pixels: 600) {
  @if $size=='tiny' {
    @media screen and (max-width: 400px) {
      @content;
    }
  }

  @if $size=='small' {
    @media screen and (max-width: $breakpoint-small) {
      @content;
    }
  }

  @else if $size=='med' {
    @media screen and (max-width: $breakpoint-med) {
      @content;
    }
  }

  @else if $size=='mid' {
    @media screen and (min-width: $breakpoint-small) and (max-width: $breakpoint-med) {
      @content;
    }
  }

  @else if $size=='large' {
    @media screen and (min-width: $breakpoint-med) {
      @content;
    }
  }

  @else if $size=='xlarge' {
    @media screen and (min-width: $breakpoint-large) {
      @content;
    }
  }

  @else if $size=='xxlarge' {
    @media screen and (min-width: $breakpoint-xlarge) {
      @content;
    }
  }

  // @else if $size=='custom' {
  //   @media screen and ($type + -width: $pixels + px) {
  //     @content;
  //   }
  // }
}


@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}